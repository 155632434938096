<template>
  <div
    v-loading="loading"
    class="template-svg-edit"
  >
    <iframe
      v-if="svgUrl"
      id="svg_edit"
      class="template-svg-edit-iframe"
      :src="svgUrl"
      :width="screenWidth"
      :height="screenHeight"
    />
    <div class="template-svg-edit-buttons">
      <div class="template-svg-edit-goods">
        <span class="template-svg-edit-goods-name">{{ $t('templateInfo.Item') }}</span>
        <div
          class="template-svg-edit-goods-select"
          @click="handleSelectGoods"
        >
          <div class="template-svg-edit-goods-select-name" :title="goodsName">
            {{ goodsName }}
          </div>
          <span class="el-icon-arrow-down" />
        </div>
      </div>
      <div
        class="template-svg-edit-preview"
        @click="handlePreview"
      >
        {{ $t('templateInfo.Preview') }}
      </div>
    </div>

    <!-- 选择商品 -->
    <selectCommodity ref="selectCommodity" :scenarioId="scenarioId" @onSelectCommodity="handleGoods"></selectCommodity>

    <!-- 选择图片 -->
    <imgSelect ref="selectImgRef" @handleConfirm="selectImg" :color="color"></imgSelect>

    <el-dialog :visible.sync="dialogImageVisible" :title="$t('templateInfo.Preview')">
      <div style="text-align:center">
        <el-image
          :src="svgImgUrl"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>

import modelUi from '@/views/taskManagement/taskManger/utils/modelUi.js'

import { parseResp, parseError } from '@/views/taskManagement/taskManger/utils/requestUtil.js'

import selectCommodity from '@/components/select-commodity/index'
import imgSelect from '@/components/select-img/index'


import { templatePreview } from '@/libs/api/taskManger.js'

import { getToken } from '@/views/taskManagement/taskManger/utils/cacheUtil.js'

import config from '@/config/index'

const { baseUrl } = config

export default {
  components: {
    selectCommodity,
    imgSelect
  },
  data() {
    return {
      loading: false,
      dialogImageVisible: false,
      dialogImageUrl: '',
      templateId: '',
      scenarioId: '',
      selectGoods: null,
      goodsName: '',
      screenWidth: 0,
      screenHeight: 0,
      svgUrl: '',
      svgImgUrl: '',
      color:''
    }
  },

  created() {
    const queryParams = this.$route.query
    this.templateId = queryParams.templateId
    this.scenarioId = queryParams.scenarioId

    // const env = process.env.NODE_ENV

    // const url = 'http://49.234.49.154:8093/template_v2.html'

    const { origin } = window.location
    const url = `${origin}/template_v2.html`

    const token = getToken()
    const svgUrl = `${url}?templateId=${this.templateId}&authorization=${token}&env=${baseUrl}`
    this.svgUrl = svgUrl
  },

  mounted() {
    // 尺寸
    this.resize()
    const that = this
    window.onresize = () => (() => {
      that.resize()
    })()

    let _this = this

    window.addEventListener('message', function (e) {
      console.log('-----监听addEventListener--message',e)
      if (e.origin !== origin) {  // 验证消息来源地址
        return;
      }
      if(e.data === 'selectImg'){
        _this.$refs.selectImgRef.open()
      }
      if(e.data && e.data.type && e.data.type === 'color'){
        console.log('color',e.data.value)
        _this.color = e.data.value
      }
    })

  },

  methods: {
    resize() {
      this.screenWidth = document.documentElement.clientWidth
      this.screenHeight = document.documentElement.clientHeight
    },
    // 选择商品---弹窗
    handleSelectGoods() {
      this.$refs.selectCommodity.open()
    },
    // 选择商品--确认
    handleGoods(res) {
      console.log('-------handleGoods',res)
      this.selectGoods = res[0]
      this.goodsName = res[0].itemName
    },
    handlePreview() {
      if (!this.selectGoods) {
        modelUi.msgError(this.$t("templateInfo.ErrorMessage1"))
      } else {
        const itemId = this.selectGoods.itemId || ''
        this.templatePreviewReq(itemId)
      }
    },
    // 预览
    templatePreviewReq(itemId) {
      const params = {
        templateId: this.templateId,
        itemId,
      }
      this.loading = true
      templatePreview(params).then(res => {
        this.loading = false
        const result = parseResp(res)
        if (result.success) {
          const previewImgUrl = result.data
          this.svgImgUrl = previewImgUrl
          this.dialogImageVisible = true
        } else {
          modelUi.msgError(result.message)
        }
      }).catch(err => {
        this.loading = false
        modelUi.msgError(parseError(err))
      })
    },

    // 选择图片
    selectImg(obj){
      console.log('选择的图片',obj)
      this.postMessage(obj)
    },
    // 发送数据给iframe
    postMessage(obj){
      var receiver = document.getElementById('svg_edit').contentWindow;
      const { origin } = window.location
      receiver.postMessage(obj.imageUrl, origin);
    }
  },
}
</script>

<style scoped lang="scss">
.template-svg-edit {
    position: relative;
}

.template-svg-edit-buttons {
    position: absolute;
    top: 45px;
    right: 10px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.template-svg-edit-goods {
    display: flex;
    align-items: center;
}

.template-svg-edit-goods-name {
    color: #000000;
    font-size: 12px;
}

.template-svg-edit-goods-select {
    border: 1px solid rgb(133, 133, 133);
    display: flex;
    align-items: center;
    background: #ffffff;
    margin-left: 5px;
    margin-right: 15px;
    padding: 0px 10px;
    cursor: pointer;
}

.template-svg-edit-goods-select-name {
    width: 100px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #000000;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.template-svg-edit-preview {
    width: 50px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #000000;
    font-size: 12px;
    background: #ffffff;
    border: 1px solid rgb(133, 133, 133);
    cursor: pointer;
}

</style>
